import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import LicenceRestrictionsBaseComponent, {
  LicenceRestrictionsData,
} from 'components/FormPage/licenceRestrictions';
import { DriverType } from 'types/forms';

type LicenceRestrictionsProps = {
  data: {
    csAddDriverLicenceRestrictionsTemplate: LicenceRestrictionsData;
  };
  location: Location;
};

const LicenceRestrictionsPage: React.FC<LicenceRestrictionsProps> = ({ data, location }) => (
  <LicenceRestrictionsBaseComponent
    data={data.csAddDriverLicenceRestrictionsTemplate}
    location={location}
    driverType={DriverType.TEMPORARY}
  />
);

export default LicenceRestrictionsPage;

export const query = graphql`
  query {
    csAddDriverLicenceRestrictionsTemplate(driver_type: { eq: "Temporary" }) {
      meta_title
      heading
    }
  }
`;
